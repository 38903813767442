"use client"

import React from "react"

export default function InterviewQuestionGeneratorSections() {
    const [openFaqIndex, setOpenFaqIndex] = React.useState(null)

    // Simple SVG icons
    const BrainIcon = () => (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
        >
            <path d="M9.5 2A2.5 2.5 0 0 1 12 4.5v15a2.5 2.5 0 0 1-4.96.44 2.5 2.5 0 0 1-2.96-3.08 3 3 0 0 1-.34-5.58 2.5 2.5 0 0 1 1.32-4.24 2.5 2.5 0 0 1 1.98-3A2.5 2.5 0 0 1 9.5 2Z" />
            <path d="M14.5 2A2.5 2.5 0 0 0 12 4.5v15a2.5 2.5 0 0 0 4.96.44 2.5 2.5 0 0 0 2.96-3.08 3 3 0 0 0 .34-5.58 2.5 2.5 0 0 0-1.32-4.24 2.5 2.5 0 0 0-1.98-3A2.5 2.5 0 0 0 14.5 2Z" />
        </svg>
    )

    const ZapIcon = () => (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
        >
            <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2" />
        </svg>
    )

    const TargetIcon = () => (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
        >
            <circle cx="12" cy="12" r="10" />
            <circle cx="12" cy="12" r="6" />
            <circle cx="12" cy="12" r="2" />
        </svg>
    )

    const ClockIcon = () => (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
        >
            <circle cx="12" cy="12" r="10" />
            <polyline points="12 6 12 12 16 14" />
        </svg>
    )

    const UsersIcon = () => (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
        >
            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
        </svg>
    )

    const SmileIcon = () => (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
        >
            <circle cx="12" cy="12" r="10" />
            <path d="M8 14s1.5 2 4 2 4-2 4-2" />
            <line x1="9" y1="9" x2="9.01" y2="9" />
            <line x1="15" y1="9" x2="15.01" y2="9" />
        </svg>
    )

    const LayoutGridIcon = () => (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
        >
            <rect x="3" y="3" width="7" height="7" />
            <rect x="14" y="3" width="7" height="7" />
            <rect x="14" y="14" width="7" height="7" />
            <rect x="3" y="14" width="7" height="7" />
        </svg>
    )

    const InboxIcon = () => (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
        >
            <polyline points="22 12 16 12 14 15 10 15 8 12 2 12" />
            <path d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z" />
        </svg>
    )

    const ChevronDownIcon = () => (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
        >
            <polyline points="6 9 12 15 18 9" />
        </svg>
    )

    const ChevronUpIcon = () => (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
        >
            <polyline points="18 15 12 9 6 15" />
        </svg>
    )

    const HelpCircleIcon = () => (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
        >
            <circle cx="12" cy="12" r="10" />
            <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
            <line x1="12" y1="17" x2="12.01" y2="17" />
        </svg>
    )

    return (
        <div>
            {/* How to Use Section */}
            <div
                style={{
                    backgroundColor: "white",
                    padding: "32px 24px",
                    textAlign: "center",
                }}
            >
                <div style={{ maxWidth: "800px", margin: "0 auto" }}>
                    <h2
                        style={{
                            fontSize: "30px",
                            fontWeight: "700",
                            color: "#4f46e5",
                            marginBottom: "8px",
                        }}
                    >
                        How to Use Our Interview Question Generator?
                    </h2>

                    <div
                        style={{
                            maxWidth: "900px",
                            margin: "24px auto 0",
                            textAlign: "left",
                        }}
                    >
                        {/* Step 1 */}
                        <div
                            style={{
                                display: "flex",
                                alignItems: "flex-start",
                                marginBottom: "24px",
                            }}
                        >
                            <div
                                style={{
                                    width: "48px",
                                    height: "48px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "#4f46e5",
                                    color: "white",
                                    fontSize: "20px",
                                    fontWeight: "700",
                                    borderRadius: "50%",
                                    boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                                }}
                            >
                                1
                            </div>
                            <div style={{ marginLeft: "16px" }}>
                                <h3
                                    style={{
                                        fontSize: "20px",
                                        fontWeight: "700",
                                    }}
                                >
                                    Select a Job Title
                                </h3>
                                <p
                                    style={{
                                        color: "#4b5563",
                                        marginTop: "4px",
                                    }}
                                >
                                    Choose from 200+ job titles or type in a
                                    custom title for a tailored interview
                                    experience.
                                </p>
                            </div>
                        </div>

                        {/* Step 2 */}
                        <div
                            style={{
                                display: "flex",
                                alignItems: "flex-start",
                                marginBottom: "24px",
                            }}
                        >
                            <div
                                style={{
                                    width: "48px",
                                    height: "48px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "#4f46e5",
                                    color: "white",
                                    fontSize: "20px",
                                    fontWeight: "700",
                                    borderRadius: "50%",
                                    boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                                }}
                            >
                                2
                            </div>
                            <div style={{ marginLeft: "16px" }}>
                                <h3
                                    style={{
                                        fontSize: "20px",
                                        fontWeight: "700",
                                    }}
                                >
                                    Choose the Seniority Level (Optional)
                                </h3>
                                <p
                                    style={{
                                        color: "#4b5563",
                                        marginTop: "4px",
                                    }}
                                >
                                    Select from Entry-Level, Mid-Level, or
                                    Senior-Level to ensure questions match
                                    experience.
                                </p>
                            </div>
                        </div>

                        {/* Step 3 */}
                        <div
                            style={{
                                display: "flex",
                                alignItems: "flex-start",
                                marginBottom: "24px",
                            }}
                        >
                            <div
                                style={{
                                    width: "48px",
                                    height: "48px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "#4f46e5",
                                    color: "white",
                                    fontSize: "20px",
                                    fontWeight: "700",
                                    borderRadius: "50%",
                                    boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                                }}
                            >
                                3
                            </div>
                            <div style={{ marginLeft: "16px" }}>
                                <h3
                                    style={{
                                        fontSize: "20px",
                                        fontWeight: "700",
                                    }}
                                >
                                    Select Question Type (Optional)
                                </h3>
                                <p
                                    style={{
                                        color: "#4b5563",
                                        marginTop: "4px",
                                    }}
                                >
                                    Pick question types like Technical,
                                    Behavioral, Situational, and more.
                                </p>
                            </div>
                        </div>

                        {/* Step 4 */}
                        <div
                            style={{
                                display: "flex",
                                alignItems: "flex-start",
                                marginBottom: "24px",
                            }}
                        >
                            <div
                                style={{
                                    width: "48px",
                                    height: "48px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "#4f46e5",
                                    color: "white",
                                    fontSize: "20px",
                                    fontWeight: "700",
                                    borderRadius: "50%",
                                    boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                                }}
                            >
                                4
                            </div>
                            <div style={{ marginLeft: "16px" }}>
                                <h3
                                    style={{
                                        fontSize: "20px",
                                        fontWeight: "700",
                                    }}
                                >
                                    Choose a Company (Optional)
                                </h3>
                                <p
                                    style={{
                                        color: "#4b5563",
                                        marginTop: "4px",
                                    }}
                                >
                                    Search from thousands of companies or add a
                                    custom one for specific interview questions.
                                </p>
                            </div>
                        </div>

                        {/* Step 5 */}
                        <div
                            style={{
                                display: "flex",
                                alignItems: "flex-start",
                                marginBottom: "24px",
                            }}
                        >
                            <div
                                style={{
                                    width: "48px",
                                    height: "48px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "#4f46e5",
                                    color: "white",
                                    fontSize: "20px",
                                    fontWeight: "700",
                                    borderRadius: "50%",
                                    boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                                }}
                            >
                                5
                            </div>
                            <div style={{ marginLeft: "16px" }}>
                                <h3
                                    style={{
                                        fontSize: "20px",
                                        fontWeight: "700",
                                    }}
                                >
                                    Generate AI-Powered Interview Questions
                                </h3>
                                <p
                                    style={{
                                        color: "#4b5563",
                                        marginTop: "4px",
                                    }}
                                >
                                    Click 'Generate' and receive 10 high-quality
                                    interview questions instantly.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Features Section */}
            <div
                style={{
                    maxWidth: "1200px",
                    margin: "0 auto",
                    padding: "32px 24px",
                    textAlign: "center",
                }}
            >
                <h2
                    style={{
                        fontSize: "30px",
                        fontWeight: "700",
                        marginBottom: "8px",
                    }}
                >
                    Why Our AI Tool?
                </h2>
                <p
                    style={{
                        color: "#4b5563",
                        maxWidth: "600px",
                        margin: "0 auto 24px",
                    }}
                >
                    Discover the powerful benefits and time-saving capabilities
                    that make our tool a must-have for interview preparation.
                </p>

                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns:
                            "repeat(auto-fit, minmax(250px, 1fr))",
                        gap: "16px",
                    }}
                >
                    {/* Feature 1 */}
                    <div
                        style={{
                            backgroundColor: "white",
                            borderRadius: "12px",
                            padding: "24px",
                            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                            transition: "box-shadow 0.3s ease",
                        }}
                    >
                        <div
                            style={{
                                width: "48px",
                                height: "48px",
                                backgroundColor: "#ede9fe",
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "0 auto 16px",
                                color: "#4f46e5",
                            }}
                        >
                            <BrainIcon />
                        </div>
                        <h3
                            style={{
                                fontSize: "18px",
                                fontWeight: "600",
                                marginBottom: "8px",
                            }}
                        >
                            Smart AI Engine
                        </h3>
                        <p style={{ color: "#4b5563", fontSize: "14px" }}>
                            Generates highly relevant questions using ChatGPT
                            for maximum impact.
                        </p>
                    </div>

                    {/* Feature 2 */}
                    <div
                        style={{
                            backgroundColor: "white",
                            borderRadius: "12px",
                            padding: "24px",
                            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                            transition: "box-shadow 0.3s ease",
                        }}
                    >
                        <div
                            style={{
                                width: "48px",
                                height: "48px",
                                backgroundColor: "#ede9fe",
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "0 auto 16px",
                                color: "#4f46e5",
                            }}
                        >
                            <ZapIcon />
                        </div>
                        <h3
                            style={{
                                fontSize: "18px",
                                fontWeight: "600",
                                marginBottom: "8px",
                            }}
                        >
                            Fast & Instant
                        </h3>
                        <p style={{ color: "#4b5563", fontSize: "14px" }}>
                            Get unique questions in seconds—no more long prep
                            times.
                        </p>
                    </div>

                    {/* Feature 3 */}
                    <div
                        style={{
                            backgroundColor: "white",
                            borderRadius: "12px",
                            padding: "24px",
                            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                            transition: "box-shadow 0.3s ease",
                        }}
                    >
                        <div
                            style={{
                                width: "48px",
                                height: "48px",
                                backgroundColor: "#ede9fe",
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "0 auto 16px",
                                color: "#4f46e5",
                            }}
                        >
                            <TargetIcon />
                        </div>
                        <h3
                            style={{
                                fontSize: "18px",
                                fontWeight: "600",
                                marginBottom: "8px",
                            }}
                        >
                            Tailored Output
                        </h3>
                        <p style={{ color: "#4b5563", fontSize: "14px" }}>
                            Based on job title, seniority, & description for
                            precise relevance.
                        </p>
                    </div>

                    {/* Feature 4 */}
                    <div
                        style={{
                            backgroundColor: "white",
                            borderRadius: "12px",
                            padding: "24px",
                            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                            transition: "box-shadow 0.3s ease",
                        }}
                    >
                        <div
                            style={{
                                width: "48px",
                                height: "48px",
                                backgroundColor: "#ede9fe",
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "0 auto 16px",
                                color: "#4f46e5",
                            }}
                        >
                            <ClockIcon />
                        </div>
                        <h3
                            style={{
                                fontSize: "18px",
                                fontWeight: "600",
                                marginBottom: "8px",
                            }}
                        >
                            Saves Time
                        </h3>
                        <p style={{ color: "#4b5563", fontSize: "14px" }}>
                            No need to spend hours formulating answers. Get
                            well-structured responses quickly.
                        </p>
                    </div>

                    {/* Feature 5 */}
                    <div
                        style={{
                            backgroundColor: "white",
                            borderRadius: "12px",
                            padding: "24px",
                            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                            transition: "box-shadow 0.3s ease",
                        }}
                    >
                        <div
                            style={{
                                width: "48px",
                                height: "48px",
                                backgroundColor: "#ede9fe",
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "0 auto 16px",
                                color: "#4f46e5",
                            }}
                        >
                            <UsersIcon />
                        </div>
                        <h3
                            style={{
                                fontSize: "18px",
                                fontWeight: "600",
                                marginBottom: "8px",
                            }}
                        >
                            Role-Specific Answers
                        </h3>
                        <p style={{ color: "#4b5563", fontSize: "14px" }}>
                            Responses are generated based on the job role,
                            company, and JD for maximum relevance.
                        </p>
                    </div>

                    {/* Feature 6 */}
                    <div
                        style={{
                            backgroundColor: "white",
                            borderRadius: "12px",
                            padding: "24px",
                            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                            transition: "box-shadow 0.3s ease",
                        }}
                    >
                        <div
                            style={{
                                width: "48px",
                                height: "48px",
                                backgroundColor: "#ede9fe",
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "0 auto 16px",
                                color: "#4f46e5",
                            }}
                        >
                            <SmileIcon />
                        </div>
                        <h3
                            style={{
                                fontSize: "18px",
                                fontWeight: "600",
                                marginBottom: "8px",
                            }}
                        >
                            Improves Confidence
                        </h3>
                        <p style={{ color: "#4b5563", fontSize: "14px" }}>
                            Provides polished, professional answers to help you
                            articulate thoughts better.
                        </p>
                    </div>

                    {/* Feature 7 */}
                    <div
                        style={{
                            backgroundColor: "white",
                            borderRadius: "12px",
                            padding: "24px",
                            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                            transition: "box-shadow 0.3s ease",
                        }}
                    >
                        <div
                            style={{
                                width: "48px",
                                height: "48px",
                                backgroundColor: "#ede9fe",
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "0 auto 16px",
                                color: "#4f46e5",
                            }}
                        >
                            <LayoutGridIcon />
                        </div>
                        <h3
                            style={{
                                fontSize: "18px",
                                fontWeight: "600",
                                marginBottom: "8px",
                            }}
                        >
                            Parallel Answer Display
                        </h3>
                        <p style={{ color: "#4b5563", fontSize: "14px" }}>
                            Generates two different responses per question, so
                            you can pick the best one.
                        </p>
                    </div>

                    {/* Feature 8 */}
                    <div
                        style={{
                            backgroundColor: "white",
                            borderRadius: "12px",
                            padding: "24px",
                            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                            transition: "box-shadow 0.3s ease",
                        }}
                    >
                        <div
                            style={{
                                width: "48px",
                                height: "48px",
                                backgroundColor: "#ede9fe",
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "0 auto 16px",
                                color: "#4f46e5",
                            }}
                        >
                            <InboxIcon />
                        </div>
                        <h3
                            style={{
                                fontSize: "18px",
                                fontWeight: "600",
                                marginBottom: "8px",
                            }}
                        >
                            No Sign-Up Required
                        </h3>
                        <p style={{ color: "#4b5563", fontSize: "14px" }}>
                            Use the tool instantly—no registration or login
                            needed to start generating questions right away.
                        </p>
                    </div>
                </div>
            </div>

            {/* Reviews Section */}
            <div style={{ backgroundColor: "#f9fafb", padding: "32px 24px" }}>
                <div
                    style={{
                        maxWidth: "800px",
                        margin: "0 auto",
                        textAlign: "center",
                        marginBottom: "24px",
                    }}
                >
                    <h2
                        style={{
                            fontSize: "30px",
                            fontWeight: "700",
                            color: "#4f46e5",
                        }}
                    >
                        25,000+ Job Seekers Already Using Interview Sidekick
                    </h2>
                    <p style={{ color: "#4b5563", marginTop: "8px" }}>
                        See what they have to say.
                    </p>
                </div>

                <div
                    style={{
                        maxWidth: "1200px",
                        margin: "0 auto",
                        display: "grid",
                        gridTemplateColumns:
                            "repeat(auto-fit, minmax(300px, 1fr))",
                        gap: "16px",
                    }}
                >
                    {/* Review 1 */}
                    <div
                        style={{
                            backgroundColor: "white",
                            borderRadius: "12px",
                            padding: "20px",
                            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                        }}
                    >
                        <div>
                            <h3
                                style={{
                                    fontSize: "18px",
                                    fontWeight: "600",
                                    color: "#4f46e5",
                                }}
                            >
                                Alice Johnson
                            </h3>
                            <p style={{ fontSize: "14px", color: "#6b7280" }}>
                                Product Manager @ Google
                            </p>
                        </div>
                        <div style={{ margin: "8px 0", display: "flex" }}>
                            {"★★★★★".split("").map((star, i) => (
                                <span
                                    key={i}
                                    style={{
                                        color: "#f59e0b",
                                        marginRight: "2px",
                                    }}
                                >
                                    {star}
                                </span>
                            ))}
                        </div>
                        <p style={{ color: "#374151" }}>
                            This AI Interview Question Generator was a
                            lifesaver! It helped me practice relevant PM
                            questions and I landed the job.
                        </p>
                    </div>

                    {/* Review 2 */}
                    <div
                        style={{
                            backgroundColor: "white",
                            borderRadius: "12px",
                            padding: "20px",
                            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                        }}
                    >
                        <div>
                            <h3
                                style={{
                                    fontSize: "18px",
                                    fontWeight: "600",
                                    color: "#4f46e5",
                                }}
                            >
                                Brian Lee
                            </h3>
                            <p style={{ fontSize: "14px", color: "#6b7280" }}>
                                UX Designer @ Airbnb
                            </p>
                        </div>
                        <div style={{ margin: "8px 0", display: "flex" }}>
                            {"★★★★★".split("").map((star, i) => (
                                <span
                                    key={i}
                                    style={{
                                        color: "#f59e0b",
                                        marginRight: "2px",
                                    }}
                                >
                                    {star}
                                </span>
                            ))}
                        </div>
                        <p style={{ color: "#374151" }}>
                            Absolutely amazing. The tailored questions boosted
                            my confidence and let me focus on design scenarios.
                        </p>
                    </div>

                    {/* Review 3 */}
                    <div
                        style={{
                            backgroundColor: "white",
                            borderRadius: "12px",
                            padding: "20px",
                            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                        }}
                    >
                        <div>
                            <h3
                                style={{
                                    fontSize: "18px",
                                    fontWeight: "600",
                                    color: "#4f46e5",
                                }}
                            >
                                Caroline Smith
                            </h3>
                            <p style={{ fontSize: "14px", color: "#6b7280" }}>
                                Data Analyst @ Netflix
                            </p>
                        </div>
                        <div style={{ margin: "8px 0", display: "flex" }}>
                            {"★★★★☆".split("").map((star, i) => (
                                <span
                                    key={i}
                                    style={{
                                        color: "#f59e0b",
                                        marginRight: "2px",
                                    }}
                                >
                                    {star}
                                </span>
                            ))}
                        </div>
                        <p style={{ color: "#374151" }}>
                            I love how fast it generates questions. It prepared
                            me well for analytics challenges during the
                            interview.
                        </p>
                    </div>

                    {/* Review 4 */}
                    <div
                        style={{
                            backgroundColor: "white",
                            borderRadius: "12px",
                            padding: "20px",
                            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                        }}
                    >
                        <div>
                            <h3
                                style={{
                                    fontSize: "18px",
                                    fontWeight: "600",
                                    color: "#4f46e5",
                                }}
                            >
                                David Martinez
                            </h3>
                            <p style={{ fontSize: "14px", color: "#6b7280" }}>
                                Frontend Developer @ Meta
                            </p>
                        </div>
                        <div style={{ margin: "8px 0", display: "flex" }}>
                            {"★★★★★".split("").map((star, i) => (
                                <span
                                    key={i}
                                    style={{
                                        color: "#f59e0b",
                                        marginRight: "2px",
                                    }}
                                >
                                    {star}
                                </span>
                            ))}
                        </div>
                        <p style={{ color: "#374151" }}>
                            The coding and behavioral questions were on point.
                            This tool made me stand out among other candidates!
                        </p>
                    </div>

                    {/* Review 5 */}
                    <div
                        style={{
                            backgroundColor: "white",
                            borderRadius: "12px",
                            padding: "20px",
                            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                        }}
                    >
                        <div>
                            <h3
                                style={{
                                    fontSize: "18px",
                                    fontWeight: "600",
                                    color: "#4f46e5",
                                }}
                            >
                                Evelyn Brown
                            </h3>
                            <p style={{ fontSize: "14px", color: "#6b7280" }}>
                                Marketing Specialist @ Amazon
                            </p>
                        </div>
                        <div style={{ margin: "8px 0", display: "flex" }}>
                            {"★★★★★".split("").map((star, i) => (
                                <span
                                    key={i}
                                    style={{
                                        color: "#f59e0b",
                                        marginRight: "2px",
                                    }}
                                >
                                    {star}
                                </span>
                            ))}
                        </div>
                        <p style={{ color: "#374151" }}>
                            A must-have for any job seeker. The variety of
                            question types helped me tackle everything from
                            culture fit to strategy.
                        </p>
                    </div>

                    {/* Review 6 */}
                    <div
                        style={{
                            backgroundColor: "white",
                            borderRadius: "12px",
                            padding: "20px",
                            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                        }}
                    >
                        <div>
                            <h3
                                style={{
                                    fontSize: "18px",
                                    fontWeight: "600",
                                    color: "#4f46e5",
                                }}
                            >
                                Frank Wilson
                            </h3>
                            <p style={{ fontSize: "14px", color: "#6b7280" }}>
                                Full Stack Dev @ Microsoft
                            </p>
                        </div>
                        <div style={{ margin: "8px 0", display: "flex" }}>
                            {"★★★★★".split("").map((star, i) => (
                                <span
                                    key={i}
                                    style={{
                                        color: "#f59e0b",
                                        marginRight: "2px",
                                    }}
                                >
                                    {star}
                                </span>
                            ))}
                        </div>
                        <p style={{ color: "#374151" }}>
                            Highly recommend! The questions were so relevant
                            that I felt like I'd already done the interview
                            before it even started.
                        </p>
                    </div>
                </div>
            </div>

            {/* FAQ Section */}
            <div style={{ backgroundColor: "white", padding: "32px 24px" }}>
                <div style={{ maxWidth: "800px", margin: "0 auto" }}>
                    <h2
                        style={{
                            fontSize: "30px",
                            fontWeight: "700",
                            color: "#4f46e5",
                            textAlign: "center",
                        }}
                    >
                        Frequently Asked Questions
                    </h2>
                    <p
                        style={{
                            textAlign: "center",
                            color: "#4b5563",
                            marginTop: "8px",
                            marginBottom: "24px",
                        }}
                    >
                        Everything you need to know about our AI Interview
                        Question Generator
                    </p>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                        }}
                    >
                        {/* FAQ 1 */}
                        <div
                            style={{
                                backgroundColor: "#f9fafb",
                                borderRadius: "12px",
                                padding: "16px",
                                boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                setOpenFaqIndex(openFaqIndex === 0 ? null : 0)
                            }
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <span
                                        style={{
                                            color: "#4f46e5",
                                            marginRight: "8px",
                                        }}
                                    >
                                        <HelpCircleIcon />
                                    </span>
                                    <span
                                        style={{
                                            fontWeight: "500",
                                            color: "#1f2937",
                                        }}
                                    >
                                        What is the AI Interview Question
                                        Generator?
                                    </span>
                                </div>
                                <span style={{ color: "#4f46e5" }}>
                                    {openFaqIndex === 0 ? (
                                        <ChevronUpIcon />
                                    ) : (
                                        <ChevronDownIcon />
                                    )}
                                </span>
                            </div>

                            {openFaqIndex === 0 && (
                                <p
                                    style={{
                                        marginTop: "12px",
                                        color: "#4b5563",
                                        paddingLeft: "32px",
                                    }}
                                >
                                    It's an AI-powered tool for job seekers to
                                    instantly generate customized, high-quality
                                    practice interview questions tailored to
                                    roles, companies, and seniority levels.
                                </p>
                            )}
                        </div>

                        {/* FAQ 2 */}
                        <div
                            style={{
                                backgroundColor: "#f9fafb",
                                borderRadius: "12px",
                                padding: "16px",
                                boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                setOpenFaqIndex(openFaqIndex === 1 ? null : 1)
                            }
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <span
                                        style={{
                                            color: "#4f46e5",
                                            marginRight: "8px",
                                        }}
                                    >
                                        <HelpCircleIcon />
                                    </span>
                                    <span
                                        style={{
                                            fontWeight: "500",
                                            color: "#1f2937",
                                        }}
                                    >
                                        How do I customize the questions for my
                                        role?
                                    </span>
                                </div>
                                <span style={{ color: "#4f46e5" }}>
                                    {openFaqIndex === 1 ? (
                                        <ChevronUpIcon />
                                    ) : (
                                        <ChevronDownIcon />
                                    )}
                                </span>
                            </div>

                            {openFaqIndex === 1 && (
                                <p
                                    style={{
                                        marginTop: "12px",
                                        color: "#4b5563",
                                        paddingLeft: "32px",
                                    }}
                                >
                                    Select a job title, choose your seniority
                                    level, pick a question type, and add the job
                                    description. Our tool will generate 10
                                    questions based on your inputs.
                                </p>
                            )}
                        </div>

                        {/* FAQ 3 */}
                        <div
                            style={{
                                backgroundColor: "#f9fafb",
                                borderRadius: "12px",
                                padding: "16px",
                                boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                setOpenFaqIndex(openFaqIndex === 2 ? null : 2)
                            }
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <span
                                        style={{
                                            color: "#4f46e5",
                                            marginRight: "8px",
                                        }}
                                    >
                                        <HelpCircleIcon />
                                    </span>
                                    <span
                                        style={{
                                            fontWeight: "500",
                                            color: "#1f2937",
                                        }}
                                    >
                                        Is it free to use or do I need a
                                        subscription?
                                    </span>
                                </div>
                                <span style={{ color: "#4f46e5" }}>
                                    {openFaqIndex === 2 ? (
                                        <ChevronUpIcon />
                                    ) : (
                                        <ChevronDownIcon />
                                    )}
                                </span>
                            </div>

                            {openFaqIndex === 2 && (
                                <p
                                    style={{
                                        marginTop: "12px",
                                        color: "#4b5563",
                                        paddingLeft: "32px",
                                    }}
                                >
                                    You can use the core features completely
                                    free. No subscription is required to
                                    generate multiple sets of questions.
                                </p>
                            )}
                        </div>

                        {/* FAQ 4 */}
                        <div
                            style={{
                                backgroundColor: "#f9fafb",
                                borderRadius: "12px",
                                padding: "16px",
                                boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                setOpenFaqIndex(openFaqIndex === 3 ? null : 3)
                            }
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <span
                                        style={{
                                            color: "#4f46e5",
                                            marginRight: "8px",
                                        }}
                                    >
                                        <HelpCircleIcon />
                                    </span>
                                    <span
                                        style={{
                                            fontWeight: "500",
                                            color: "#1f2937",
                                        }}
                                    >
                                        Can I use it for both technical and
                                        behavioral interviews?
                                    </span>
                                </div>
                                <span style={{ color: "#4f46e5" }}>
                                    {openFaqIndex === 3 ? (
                                        <ChevronUpIcon />
                                    ) : (
                                        <ChevronDownIcon />
                                    )}
                                </span>
                            </div>

                            {openFaqIndex === 3 && (
                                <p
                                    style={{
                                        marginTop: "12px",
                                        color: "#4b5563",
                                        paddingLeft: "32px",
                                    }}
                                >
                                    Yes! Choose from various question types like
                                    Technical, Behavioral, System Design,
                                    Leadership, and more to cover all aspects of
                                    your prep.
                                </p>
                            )}
                        </div>

                        {/* FAQ 5 */}
                        <div
                            style={{
                                backgroundColor: "#f9fafb",
                                borderRadius: "12px",
                                padding: "16px",
                                boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                setOpenFaqIndex(openFaqIndex === 4 ? null : 4)
                            }
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <span
                                        style={{
                                            color: "#4f46e5",
                                            marginRight: "8px",
                                        }}
                                    >
                                        <HelpCircleIcon />
                                    </span>
                                    <span
                                        style={{
                                            fontWeight: "500",
                                            color: "#1f2937",
                                        }}
                                    >
                                        How many questions can I generate at
                                        once?
                                    </span>
                                </div>
                                <span style={{ color: "#4f46e5" }}>
                                    {openFaqIndex === 4 ? (
                                        <ChevronUpIcon />
                                    ) : (
                                        <ChevronDownIcon />
                                    )}
                                </span>
                            </div>

                            {openFaqIndex === 4 && (
                                <p
                                    style={{
                                        marginTop: "12px",
                                        color: "#4b5563",
                                        paddingLeft: "32px",
                                    }}
                                >
                                    Each generation gives you 10 unique
                                    questions. You can re-run the generator
                                    anytime for new sets.
                                </p>
                            )}
                        </div>

                        {/* FAQ 6 */}
                        <div
                            style={{
                                backgroundColor: "#f9fafb",
                                borderRadius: "12px",
                                padding: "16px",
                                boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                setOpenFaqIndex(openFaqIndex === 5 ? null : 5)
                            }
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <span
                                        style={{
                                            color: "#4f46e5",
                                            marginRight: "8px",
                                        }}
                                    >
                                        <HelpCircleIcon />
                                    </span>
                                    <span
                                        style={{
                                            fontWeight: "500",
                                            color: "#1f2937",
                                        }}
                                    >
                                        What if I need help or run into issues?
                                    </span>
                                </div>
                                <span style={{ color: "#4f46e5" }}>
                                    {openFaqIndex === 5 ? (
                                        <ChevronUpIcon />
                                    ) : (
                                        <ChevronDownIcon />
                                    )}
                                </span>
                            </div>

                            {openFaqIndex === 5 && (
                                <p
                                    style={{
                                        marginTop: "12px",
                                        color: "#4b5563",
                                        paddingLeft: "32px",
                                    }}
                                >
                                    Our support team is here for you. Reach out
                                    anytime via the contact form on our website
                                    for assistance.
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
